import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {PersonService} from '../person/person.service';
import {GetPerson, GetPersonFail, GetPersonSuccess, UpdatePerson, UpdatePersonFail, UpdatePersonSuccess} from './profile.actions';
import {Person} from '../../symbols';
import {BackendError, SuccessMessage} from '../../../messages/messages.actions';


interface ProfileStateModel {
  person: Person;
  loading: boolean;
  loaded: boolean;
}


@State<ProfileStateModel>({
  name: 'profileState',
  defaults: {
    person: null,
    loading: false,
    loaded: false
  },
})


@Injectable()
export class ProfileState {

  constructor(
    public personService: PersonService,
  ) {
  }

  @Selector()
  static loading(state: ProfileStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static person(state: ProfileStateModel): Person {
    return state.person;
  }

  @Action(GetPerson)
  getPerson({patchState, dispatch}: StateContext<ProfileStateModel>) {
    patchState({
      loading: true,
      loaded: false,
    });
    this.personService.retrieve().subscribe(
      person => dispatch(new GetPersonSuccess(person)),
      err => dispatch(new GetPersonFail(err)),
    );
  }

  @Action(GetPersonSuccess)
  getPersonSuccess({patchState}: StateContext<ProfileStateModel>, {payload}: GetPersonSuccess | UpdatePersonSuccess) {
    patchState({
      person: payload,
      loading: false,
      loaded: true,
    });
  }

  @Action(UpdatePerson)
  updatePerson({patchState, dispatch}: StateContext<ProfileStateModel>, {payload}: UpdatePerson) {
    patchState({
      loading: true,
      loaded: false,
    });
    this.personService.partialUpdate(payload.id, payload).subscribe(
      person => dispatch(new UpdatePersonSuccess(person)),
      err => dispatch(new UpdatePersonFail(err)),
    );
  }

  @Action(UpdatePersonSuccess)
  updatePersonSuccess(ctx: StateContext<ProfileStateModel>, {payload}: GetPersonSuccess | UpdatePersonSuccess) {
    ctx.patchState({
      person: payload,
      loading: false,
      loaded: true,
    });
    ctx.dispatch([
      new SuccessMessage('Your personal data was successfully updated'),
    ]);
  }

  @Action([UpdatePersonFail, GetPersonFail])
  onFail(ctx: StateContext<ProfileStateModel>, {err}: UpdatePersonFail | GetPersonFail) {
    ctx.patchState({
      loading: false,
      loaded: false,
    });
    ctx.dispatch(new BackendError(err));
  }
}
