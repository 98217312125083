import {Submission} from '../../dashboard/symbols/submission.symbols';
import {UserSexCode} from '../../dashboard/symbols/general.symbols';

function parseSubmissionReasonData(reason: string) {
  try {
    return JSON.parse(reason) || {};
  } catch (e) {
    return {};
  }
}

export function getAge(submission): string {
  const DEFAULT = '-';
  const data = parseSubmissionReasonData(submission.reason);
  return data.age || DEFAULT;
}

export function getSex(submission): string {
  const DEFAULT = '-';
  const data = parseSubmissionReasonData(submission.reason);
  return data.sex || DEFAULT;
}

export function getSexCode(submission): UserSexCode {
  const sex = getSex(submission);
  if (sex.toLowerCase() === 'male') {
    return UserSexCode.Male;
  } else if (sex.toLowerCase() === 'female') {
    return UserSexCode.Female;
  } else {
    return null;
  }
}

export function getReasonText(submission): string {
  const DEFAULT = '';
  const data = parseSubmissionReasonData(submission.reason);
  return data.text || DEFAULT;
}

export function getSubmissionSexCode(submission: Submission): UserSexCode {
  const sex = submission?.sex?.toLowerCase();

  if (sex === 'm') {
    return UserSexCode.Male;
  } else if (sex === 'f') {
    return UserSexCode.Female;
  } else {
    return null;
  }
}
