import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {omit} from 'lodash';
import {Person} from '../../symbols';


@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private http: HttpClient) {
  }

  _filter(person: Person): Person {
    const filtering = ['id', 'date_of_birth'];
    const ignore = filtering.filter(x => !person[x]);
    if (ignore.length) {
      return omit(person, ignore);
    }
    return person;
  }

  create(data: Person): Observable<Person> {
    const person = this._filter(data);
    return this.http.post<Person>(' /api/persons/', person).pipe(retry(3));
  }

  list(): Observable<Person[]> {
    return this.http.get<Person[]>('/api/persons/').pipe(retry(3));
  }

  /**
   * Retrieve the person by id. If you need the current person, ask 'current' id
   * @param id
   */
  retrieve(id: number | string = null): Observable<Person> {
    if (!id) {
      id = 'current';
    }
    return this.http.get<Person>(`/api/persons/${id}/`).pipe(retry(3));
  }

  partialUpdate(id, data: Person) {
    const person = this._filter(data);
    return this.http.patch(`/api/persons/${id}/`, person).pipe(retry(3));
  }
}
