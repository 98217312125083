import {Person} from '../../symbols';

export class GetPerson {
  static type = '[Profile] Get Person';
}

export class GetPersonSuccess {
  static type = '[Profile] Get Person Success';
  constructor(public payload: Person) {}
}

export class GetPersonFail {
  static type = '[Profile] Get Person Fail';
  constructor(public err: any) {}
}

export class UpdatePerson {
  static type = '[Profile] Update Person';
  constructor(public payload: Person) {}
}

export class UpdatePersonSuccess {
  static type = '[Profile] Update Person Success';
  constructor(public payload: Person) {}
}

export class UpdatePersonFail {
  static type = '[Profile] Update Person Fail';
  constructor(public err: any) {}
}
