import {PapayaRGBColor} from '../../dashboard-papaya/symbols/dashboard-papaya-general.symbols';

export interface DashboardFlowStepsItem {
  label: string;
  url: string;
  stepNumber?: number;
}

export interface UserFeedback {
  message: string;
}

/**
 * Describes the relation between numeric value and sex it represents.
 */
export enum UserSexCode {
  Male = 1,
  Female = 2,
}

/**
 * The color that is applied to the data points for the typical values.
 */
export const DashboardTypicalPointValueColor = '#2E4FD7';

/**
 * The color that is applied to the data points for the atypical values.
 */
export const DashboardAtypicalPointValueColor = '#EB5463';

/**
 * The color that is applied to the brain parts for the typical values.
 * Hex color: #4D69DD
 */
export const DashboardTypicalPartValueColor: PapayaRGBColor = [0.30196078431372547, 0.4117647058823529, 0.8666666666666667];

/**
 * Describe available types of the image scan.
 */
export enum SubmissionScanImageType {
  /** T1 weighted image scan; the main type of the image scans. */
  t1 = 'T1',

  /** T2 weighted image scan; secondary type of the image scans; related to the PVS. */
  t2 = 'T2',

  /** FLAIR image scan; a secondary type of the image scans; related to the WMH. */
  flair = 'Flair',

  /** TOF image scan; a secondary type of the image scans; related to the Time of Flight. */
  tof = 'TOF',
}


