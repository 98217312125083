import {DashboardBrainPart, DashboardBrainPartsFlatListMap, DashboardBrainPartsParentMap} from '../../symbols/dashboard-brain-parts-general.symbols';
import {PROGRESS_STATUSES, UnknownError} from '../../../shared/symbols';

/** Unique identifier for the dashboard brain parts state. */
export const dashboardBrainPartsUniqueStateId = '_DashboardBrainParts_';

/** Describes dashboard brain parts state fields. */
export interface DashboardBrainPartsStateModel {
  /** Load brain part list progress status. */
  loadBrainPartsStatus: PROGRESS_STATUSES;

  /** Last error that occurs during load brain part list process. */
  lastLoadBrainPartsError: UnknownError;

  /** List of available brain parts. */
  brainPartList: DashboardBrainPart[];

  /** Map of brain parts id and their parents id. */
  brainPartsParentMap: DashboardBrainPartsParentMap;

  /** Map of brain parts id and the related brain parts. */
  brainPartsFlatListMap: DashboardBrainPartsFlatListMap;

  /** Priority for loading brain part surfaces. */
  brainPartSurfacesLoadingPriority: number[][];

  /** Selected brain part of the main level (displayed in the carousel). */
  selectedMainLevelBrainPart: DashboardBrainPart;

  /** Selected brain part of any level (displayed in the tree view). */
  lastSelectedBrainPart: DashboardBrainPart;

  /**
   * The brain part that should be displayed in the chart.
   * It can be different from the currently selected brain part tag when the sublevel brain part without a volume & fraction data is selected.
   */
  brainPartToShowInChart: DashboardBrainPart;
}

/** Default values for the dashboard brain parts state initialization and reset. */
export const dashboardBrainPartsStateDefault: DashboardBrainPartsStateModel = {
  loadBrainPartsStatus: PROGRESS_STATUSES.NOT_INITIALIZED,
  lastLoadBrainPartsError: null,
  brainPartList: [],

  brainPartsParentMap: null,
  brainPartsFlatListMap: null,
  brainPartSurfacesLoadingPriority: [],

  selectedMainLevelBrainPart: null,
  lastSelectedBrainPart: null,

  brainPartToShowInChart: null,
};
