import {UnknownError} from '../../symbols';
import {Submission} from '../../../dashboard/symbols/submission.symbols';
import {BrainAge} from './submission.models';
import {CustomRegion} from '../../../dashboard/symbols/scan-viewer.symbols';
import {UserSharedAccountData} from '../../../dashboard-clinic-patients/symbols/dashboard-clinic-patients.symbols';

import {User} from '../../../auth/symbols';

export class InitSubmissions {
  static type = '[Submissions] Init Submissions';
}

export class ReloadSubmissions {
  static type = '[Submissions] Reload Submissions';
}

export class LoadPublicSubmissions {
  static type = '[Submissions] Load Public Submissions';
}

export class LoadPublicSubmissionsSuccess {
  static type = '[Submissions] Load Public Submissions Success';
  constructor(public publicSubmissions: Submission[]) {}
}

export class LoadPrivateSubmissions {
  static type = '[Submissions] Load Private Submissions';
}

export class LoadPrivateSubmissionsSuccess {
  static type = '[Submissions] Load Private Submissions Success';
  constructor(public privateSubmissions: Submission[]) {}
}

export class SelectSubmission {
  static type = '[Submissions] Select Submission';
  constructor(public id: string, public isPublic: boolean, public isStatic: boolean = false) {}
}

export class LoadSubmissionsSuccess {
  static type = '[Submissions] Load Submissions Success';

  constructor(public submissions: Submission[]) {}
}

export class LoadSubmission {
  static type = '[Submissions] Load Submission';
  constructor(public submissionId: string, public isPublic: boolean) {}
}

export class LoadSubmissionSuccess {
  static type = '[Submissions] Load Submission Success';
  constructor(public submission: Submission) {}
}

export class LoadSubmissionFail {
  static type = '[Submissions] LoadSubmissionFail';
  constructor(public error: UnknownError) {}
}

export class LoadFail {
  static type = '[Submissions] Load Fail';
  constructor(public error: any) {}
}

export class UpdateSubmissions {
  static type = '[Submissions] Update';
  constructor(public submissions: Submission[]) {}
}

export class UpdateSingleSubmission {
  static type = '[Submissions] UpdateSingleSubmission';
  constructor(public submissionId: Submission['id'], public submission: Partial<Submission>) {}
}

export class UpdateSingleSubmissionSuccess {
  static type = '[Submissions] UpdateSingleSubmissionSuccess';
  constructor(public submissionId: Submission['id'], public submission: Partial<Submission>) {}
}

export class UpdateSingleSubmissionFail {
  static type = '[Submissions] UpdateSingleSubmissionFail';
  constructor(public submissionId: Submission['id'], public error: UnknownError) {}
}

export class SaveSubmission {
  static type = '[Submission] SaveSubmission';
  constructor(public submission: Submission) {}
}

export class SaveSubmissionSuccess {
  static type = '[Submission] SaveSubmissionSuccess';
  constructor(public submission: Submission) {}
}

export class SaveSubmissionFail {
  static type = '[Submission] SaveSubmissionFail';
  constructor(public error: UnknownError) {}
}

export class LoadUserRelatedSubmissions {
  static type = '[Submissions] LoadUserRelatedSubmissions';
  constructor(public userAccount: UserSharedAccountData) {}
}

export class LoadUserRelatedSubmissionsSuccess {
  static type = '[Submissions] LoadUserRelatedSubmissionsSuccess';
  constructor(public relatedSubmissions: Submission[]) {}
}

export class LoadUserRelatedSubmissionsFail {
  static type = '[Submissions] LoadUserRelatedSubmissionsFail';
  constructor(public error: UnknownError) {}
}

export class FocusCustomViewerRegion {
  static type = '[Submissions] FocusCustomViewerRegion';
  constructor(public region: CustomRegion) {}
}

export class UpdateCustomViewerRegionList {
  static type = '[Submissions] UpdateCustomViewerRegionList';
  constructor(public regionList: CustomRegion[]) {}
}

export class LoadBrainAge {
  static type = '[Submissions] Load Brain Age';
  constructor() {}
}

export class LoadBrainAgeSuccess {
  static type = '[Submissions] Load Brain Age Success';
  constructor(public brainAge: BrainAge) {}
}
