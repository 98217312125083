import {Person} from '../../symbols';

export class LoadPersons {
  static type = '[Person] Load Persons';
}

export class LoadPersonsSuccess {
  static type = '[Person] Load Persons Success';
  constructor(public persons: Person[]){};
}

export class CreatePerson {
  static type = '[Person] Create Person';
  constructor(public detail: Person) {};
}

export class CreatePersonSuccess {
  static type = '[Person] Create Person Success';
  constructor(public detail: Person) {};
}

export class LoadCurrentPerson {
  static type = '[Person] Load Current Person';
}

export class LoadCurrentPersonSuccess {
  static type = '[Person] Load Current Person Success';
  constructor(public detail: Person) {};
}

export class SelectForSubmission {
  static type = '[Person] Select for Submission';
  constructor(public id: number) {};
}

export class RetrievePersonDetail {
  static type = '[Person] Retrieve Person Detail';
  constructor(public id: number) {};
}

export class RetrievePersonDetailSuccess {
  static type = '[Person] Retrieve Person Detail Success';
  constructor(public detail: Person) {};
}
