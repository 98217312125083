import {DashboardBrainPartsStateModel, dashboardBrainPartsUniqueStateId} from './dashboard-brain-parts.model';
import {DashboardBrainPart, DashboardBrainPartSecondaryLevel} from '../../symbols/dashboard-brain-parts-general.symbols';
import {UnknownError} from '../../../shared/symbols';

export class LoadDashboardBrainParts {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${LoadDashboardBrainParts.name}`;
}

export class LoadDashboardBrainPartsSuccess {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${LoadDashboardBrainPartsSuccess.name}`;

  constructor(public brainParts: DashboardBrainPart[]) {}
}

export class LoadDashboardBrainPartsFail {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${LoadDashboardBrainPartsFail.name}`;

  constructor(public error: UnknownError) {}
}

export class SelectMainLevelBrainPart {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${SelectMainLevelBrainPart.name}`;

  constructor(public brainPart: DashboardBrainPartsStateModel['selectedMainLevelBrainPart']) {}
}

export class SelectSublevelBrainPart {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${SelectSublevelBrainPart.name}`;

  constructor(public brainPart: DashboardBrainPartSecondaryLevel) {}
}

export class SelectDefaultBrainPart {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${SelectDefaultBrainPart.name}`;
}

export class ResetDashboardBrainPartsLoadingStatus {
  static readonly type = `${dashboardBrainPartsUniqueStateId} ${ResetDashboardBrainPartsLoadingStatus.name}`;
}


