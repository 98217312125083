import {LabelVolume, PROGRESS_STATUSES, UnknownError} from '../../symbols';
import {Submission} from '../../../dashboard/symbols/submission.symbols';
import {CustomRegion} from '../../../dashboard/symbols/scan-viewer.symbols';


/** Describe data used for start scan upload. */
export interface BeginScanUploadData {

  /** Scan files names. */
  names: string[];

  /** Calculated checksum of files for upload. */
  checksum: string;

  /** Should be provided if scan upload for another user (should contain email). */
  user?: string;

  /** Should be provided if scan related to imaging center and uploaded for specific user as result of reservation. */
  reservation_id?: number;

  /** Should be provided if scan related to imaging center and uploaded for specific clinic. */
  clinic_id?: string;
}

export interface SubmissionStateModel {
  loading: boolean;
  loaded: boolean;
  initialized: boolean;
  error?: any;
  selected?: string;
  list: string[];
  origin?: Submission[];
  submissions: {[key: string]: Submission};
  statusUploadedFiles: number;
  statusWaitingFiles: number;
  customViewerRegion?: CustomRegion;
  customViewerRegionList?: CustomRegion[];
  labelVolumeDict?: {[key: string]: LabelVolume};
  percentage?: {[key: string]: number};
  brainAge?: number;

  isStaticSubmissionSelected?: boolean;

  privateSubmissionsLoaded: boolean;
  publicSubmissionsLoaded: boolean;

  /** Status of current loading data related to the specific submission. */
  loadSubmissionProgress: PROGRESS_STATUSES;

  /** Updating status of each single submission. */
  updateSingleSubmissionProgress: {[submissionId: number]: PROGRESS_STATUSES};

  /** Last error that appeared during the update single submission. */
  updateSingleSubmissionError: UnknownError;
}

export const SUBMISSION_STATE_DEFAULT: SubmissionStateModel = {
  loaded: false,
  loading: false,
  initialized: false,
  error: null,
  selected: null,
  list: [],
  origin: null,
  submissions: {},
  statusUploadedFiles: null,
  statusWaitingFiles: null,
  customViewerRegion: null,
  customViewerRegionList: [],
  labelVolumeDict: null,
  brainAge: null,

  isStaticSubmissionSelected: false,

  privateSubmissionsLoaded: false,
  publicSubmissionsLoaded: false,

  loadSubmissionProgress: PROGRESS_STATUSES.NOT_INITIALIZED,

  updateSingleSubmissionProgress: {},
  updateSingleSubmissionError: null,
};

export interface BrainAge {
  brain_age: number;
}
