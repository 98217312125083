import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {BeginScanUploadData, BrainAge} from './submission.models';
import {Submission} from '../../../dashboard/symbols/submission.symbols';

@Injectable({
  providedIn: 'root',
})
export class SubmissionsService {
  HAS_UPLOADED_COOKIE_KEY = 'has_uploaded';

  constructor(private http: HttpClient, private cookie: CookieService) {}

  /**
   * Generate a surface map using provided labels.
   * May be used even for static surfaces.
   */
  public generateSurfaceMap(loadingOrder: number[]): {[label: string]: number} {
    // Object that contains relation between surface label, and its index in the surface array.
    const labelValueRelation = {};
    loadingOrder.forEach((val, idx) => {
      labelValueRelation[val] = idx;
    });
    return labelValueRelation;
  }

  getScanPart(isPublic: boolean) {
    return isPublic ? 'public_scan' : 'scan';
  }

  list(isAuthenticated: boolean): Observable<Submission[]> {
    if (!isAuthenticated) {
      return this.getPublicSubmissionsList();
    } else {
      const observableList = [this.getPrivateSubmissionsList(), this.getPublicSubmissionsList()];
      return forkJoin(observableList).pipe(map(resultList => resultList[0].concat(resultList[1])));
    }
  }

  getPrivateSubmissionsList(): Observable<Submission[]> {
    return this.http.get<Submission[]>('/api/research/scan/submissions/');
  }

  getPublicSubmissionsList(): Observable<Submission[]> {
    return this.http.get<Submission[]>('/api/research/public_scan/submissions/');
  }

  loadSubmissionDetails(id: string | number, isPublic: boolean = false): Observable<Submission> {
    const scanPart = this.getScanPart(isPublic);
    return this.http.get<Submission>(`/api/research/${scanPart}/submissions/${id}/`);
  }

  delete(id) {
    return this.http.patch(`/api/research/scan/submissionsssions/${id}/`, {status: 'r'});
  }

  beginCloudUpload(uploadDetails: BeginScanUploadData): Observable<any> {
    const url = `api/submission/upload/begin`;
    return this.http.post<any>(url, uploadDetails);
  }

  uploadMriRecordsData(names: string[], mriRecordsRequestID: number = null): Observable<any> {
    const payload: {names: string[]; mri_records_request_id?: number} = {names};

    if (mriRecordsRequestID) {
      payload.mri_records_request_id = mriRecordsRequestID;
    }

    return this.http.post<any>('api/submission/upload/begin', payload);
  }

  sendFileCloud(blob: Blob, url: string): Observable<HttpEvent<any>> {
    const req = new HttpRequest('PUT', url, blob, {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': '', // Cloud Storage doesn't like that header
      }),
    });
    return this.http.request(req);
  }

  finishCloudUpload(seriesId: number): Observable<number> {
    // Return provided submission series id once the request is finished.
    return this.http.post<HttpResponse<any>>(`api/submission/upload/finished/${seriesId}/`, {}).pipe(map(_ => seriesId));
  }

  updateSubmission(submission: Submission) {
    return this.http.patch<Submission>(`/api/research/scan/submissions/${submission.id}/`, submission);
  }

  hasUploaded(): boolean {
    return !!this.cookie.get(this.HAS_UPLOADED_COOKIE_KEY);
  }

  hasUploadedSave() {
    this.cookie.set(this.HAS_UPLOADED_COOKIE_KEY, '1', 10000);
  }

  hasUploadedDelete() {
    this.cookie.delete(this.HAS_UPLOADED_COOKIE_KEY);
  }

  getBrainAge(submissionId: number, isPublic: boolean): Observable<BrainAge> {
    const scanPart = this.getScanPart(isPublic);
    return this.http.get<BrainAge>(`/api/research/${scanPart}/brain-age/${submissionId}`);
  }
}
